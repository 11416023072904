import { configureStore } from "@reduxjs/toolkit";
import TimeoutSliceQA from "./components/common/Timeout/TimeoutSliceQA";
import PendingQuestionSlice from "./components/common/questionAnswer/PendingQuestionSlice";
import QuestionAnswerSlice from "./components/common/questionAnswer/QuestionAnswerSlice"
import PerformanceSlice from "./components/performance/PerformanceSlice";
import InfoBannerSlice from "./components/common/infoBanner/InfoBannerSlice";
import ColorsSwitcherSlice from "./components/common/ColorsSwitcher/ColorsSwitcherSlice";
import DashboardInfoQASlice from "./components/base/DashboardInfoQASlice";
import DashboardChartSlice from "./components/base/DashboardChartSlice";
import ComplianceSlice from "./components/performance/ComplianceSlice";
import NotificationSlice from "./components/common/notification/NotificationSlice";
import LauralUserBadgeSlice from "./components/navbar/LauralUserBadgeSlice";
import LauralTeamBadgeSlice from "./components/navbar/LauralTeamBadgeSlice";

export const store = configureStore({
    reducer: {
        questionAnswer: QuestionAnswerSlice,
        pendingQuestions: PendingQuestionSlice,
        timeoutQAstate: TimeoutSliceQA,
        performance: PerformanceSlice,
        info: InfoBannerSlice,
        theme: ColorsSwitcherSlice,
        dashboardInfoQA: DashboardInfoQASlice,
        dashboardChart: DashboardChartSlice,
        compliance: ComplianceSlice,
        notifications: NotificationSlice,
        lauralUserBadge: LauralUserBadgeSlice,
        lauralTeamBadge: LauralTeamBadgeSlice
    },
});
