import React from "react";
import './ImaColorInfo.css';

const RedCard = () => {
    return (
        <div className="top-card">
            <section className="congrats-section">
                <h1>Congratulations!</h1>
                <div className="intro-container">
                    <div className="top-right-image">
                        <img src="/red.svg" alt="iMA High Red" style={{
                            transform: "rotate(30deg)"
                        }}/>
                    </div>
                    <p className="intro-text">
                        When someone asks, "What Colour are You?" tell them "<strong>iMA High Red</strong>"!
                    </p>
                    <p className="intro-text">
                        You are cool, independent and competitive. You shape the environment that you work in by overcoming opposition in order to accomplish results. You need to be in control of your situation and you need tangible evidence of progress.
                    </p>
                </div>
            </section>

            <section className="answer-yes-section">
                <h2>iMA High Reds answer Yes!</h2>
                <p>High Reds tend to answer "Yes!" to many of the following questions:</p>
                <div className="questions-grid">
                    <div>
                        <ul>
                            <li>Do you work impressively by yourself and require very little external motivation to achieve your goals?</li>
                            <li>Do you tend to make quick decisions as soon as you see the benefits of a product or service?</li>
                            <li>Do you move forward confidently, even if the decision was not 100% correct, knowing you can make mid-course corrections?</li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>Do your rational thoughts and logic run the show?</li>
                            <li>Do you move forward confidently, even if the decision was not 100% correct, knowing you can make mid-course corrections?</li>
                            <li>Do your rational thoughts and logic run the show?</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="needs-section">
                <h3>High Reds Need: Control</h3>
                <div className="needs-grid">
                    <ul>
                        <li><strong>You Want:</strong> To be in charge</li>
                        <li><strong>For Others:</strong> To be "to the point"</li>
                    </ul>
                    <ul>
                        <li><strong>To Maintain:</strong> Success</li>
                        <li><strong>Excited by:</strong> Action</li>
                    </ul>
                </div>
            </section>

            <footer>
                <img src="/edf_logo.svg" width="200px" alt=""></img>
            </footer>
        </div>
    );
};

export default RedCard;