import { createSlice } from "@reduxjs/toolkit";
// import { wellbeingConfig, wellbeingConfig2 } from "../../__tests__/TestData";

const initState = {
    fullName: "",
    fullNameInitials: "",
    language: "en",
    logo: "",
    hideTimer: false,
    hideTimerButtonEnabled: false,
    nellySupportEmailAddress: "",
    previewMode: false,
    scoreButtonEnabled: true,
    buttonToAnswerEnabled: undefined,
    displayButtonToAnswer: false,
    setbuttonToAnswerCookies: undefined,
    customStyle: "nelly",  //"wellbeing"
    hideSidebarOnDefault: true,
    accountId: undefined,
    isConfigDataReady: undefined,
    customLogo: undefined,
    customFooter: undefined,
    customLinkText: undefined,
    isInfoMode: false,
    feedbackEmail: undefined

};

const DashboardInfoQASlice = createSlice({
    name: "dashboardInfoQA",
    initialState: initState,
    reducers: {
        setConfigurationData: (_state, { payload }) => {
            // payload = wellbeingConfig2;
            const keys = Object.keys(payload);
            keys.forEach((keyi) => {
                _state[keyi] = payload[keyi];
            });

        },
        setShowHideTimer: (state, { payload }) => {
            state.hideTimer = payload;
        },
        setPreviewMode: (state, { payload }) => {
            state.previewMode = payload;
        },

        setDisplayButtonToAnswer: (state, { payload }) => {
            state.displayButtonToAnswer = payload;
        },
        setIsConfigDataReady: (state, { payload }) => {
            state.isConfigDataReady = payload;
        },

        updateLogo: (_state, { payload }) => {
            _state.logo = payload?.logo;
            _state.isConfigDataReady = true;
        },
        setIsInfoMode: (state, {payload}) => {
            state.isInfoMode = payload;
        },
        updateImaColorData: (_state, { payload }) => {
            _state.imaColorData = payload;
        },

        reset: () => initState,
    },
});

export const {
    reset,
    setConfigurationData,
    setShowHideTimer,
    setPreviewMode,
    setDisplayButtonToAnswer,
    setIsConfigDataReady,
    updateLogo,
    setIsInfoMode,
    updateImaColorData
} = DashboardInfoQASlice.actions;
export default DashboardInfoQASlice.reducer;
