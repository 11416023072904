import React, { useState } from 'react';
import { Modal, Box, IconButton, TextField, Button, Typography, CircularProgress, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { updateQuestionAndAnswerFeedback } from './QuestionAnswerSlice';
import { useDispatch, useSelector } from 'react-redux';
import "./Freetext.saas";
import variables from "../../../variables.sass";
import DOMPurify from 'dompurify';
import validator from 'validator';
import { endPoints, logMessage } from './DataProvider';

const FreeTextInputDialog = ({ t, save, freeTextFeedback, setDialogState, customStyle }) => {
    const [open, setOpen] = useState(true);

    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [savedSuccesfully, setSavedSuccesfully] = useState(undefined);
    const [freeTextFeedbackInput, setFreeTextFeedbackInput] = useState({ ...freeTextFeedback })

    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);

    const handleClose = () => {
        setDialogState(false);
        setSavedSuccesfully(undefined);
        setOpen(false);
    };



    const handleAfterSubmit = (isSuccesfull) => {
        setLoading(false)
        if (isSuccesfull) {
            // console.log("freeTextFeedbackInput ", freeTextFeedbackInput)
            dispatch(updateQuestionAndAnswerFeedback(freeTextFeedbackInput));
        }
        setSavedSuccesfully(isSuccesfull);
    }
    const sanitizeInput = (input) => {
        // Sanitize the input to avoid XSS attacks
        return DOMPurify.sanitize(input);
    };
    const handleSubmit = () => {

        // Simple validation: Check for insecure links
        const isInsecureLink = (input) => /http:\/\/|ftp:\/\//i.test(input);
        const validateInput = (input) => {
            // console.log("input", input)
            let isValid = true;
            if (isInsecureLink(input)) {
                logMessage({ message: 'InsecureLinks' }, trackingCode, endPoints.UserFeedback);
                setError(t('Input contains an insecure link. Please use only secure (https) links.'));
                isValid = false
            }
            if (!!input && !validator.isLength(input, { min: 1, max: 1000 })) {
                logMessage({ message: 'Input length is not valid' }, trackingCode, endPoints.UserFeedback);
                setError(t('Input length is not valid'));
                isValid = false
            }

            if (!!input && (validator.contains(input, '<') || validator.contains(input, '>'))) {
                logMessage({ message: 'Invalid characters in input' }, trackingCode, endPoints.UserFeedback);
                setError(t('Invalid character (<,>) in input'));
                isValid = false
            }

            return isValid;
        };
        if (!freeTextFeedbackInput?.questionFeedback && !freeTextFeedbackInput?.answerFeedback) {
            setError(t('Empty input can not be saved'));
            return;
        }
        if (!validateInput(freeTextFeedbackInput?.questionFeedback) || !validateInput(freeTextFeedbackInput?.answerFeedback)) {
            return;
        }
        setError('');
        setLoading(true);
        save(t, freeTextFeedbackInput, handleAfterSubmit)
    };

    // Handle the change event
    const handleAnswerInputChange = (value) => {
        const sanitizedValue = sanitizeInput(value);
        setFreeTextFeedbackInput(prevState => ({
            ...prevState,
            answerFeedback: sanitizedValue
        }));
    };

    const handleQuestionInputChange = (value) => {
        const sanitizedValue = sanitizeInput(value);
        setFreeTextFeedbackInput(prevState => ({
            ...prevState,
            questionFeedback: sanitizedValue
        }));
    };
    return (
        <Modal
            className='freetextDialog'
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid={'freetextDialog'}
        >
            {loading ?
                (<Box sx={ntloadingBoxStyle} gap={2}>
                    <CircularProgress color="primary" />
                    {t("Please wait...")}
                </Box>)
                :
                savedSuccesfully != undefined ? (
                    <Box sx={ntloadingBoxStyle} gap={2} data-testid={'savedSuccesfully'} >
                        <Grid>
                            <Typography component="savedSuccesfully" >
                                {savedSuccesfully ? t('Saved successfully!') : t('Can not be saved, please try again later!')}

                            </Typography>
                            <Button
                                variant={customStyle}
                                onClick={() => handleClose()}
                                sx={{ marginLeft: 10, marginTop: 2, width: "20%", textTransform: "none" }}
                            >
                                {t('Ok')}
                            </Button>
                        </Grid>
                    </Box>
                )
                    : (<Box sx={inputBoxStyle} gap={2} p={4} position="relative" width={600} bgcolor="white" borderRadius={2}>

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
                            {t("Feedback")}
                        </Typography>
                        {freeTextFeedbackInput?.questionLabel && <div >
                            <Typography component="Questionlabel" data-testid={'questionlabel'}>
                                {freeTextFeedbackInput?.questionLabel}
                            </Typography>
                            <TextField
                                id={`questionFeedback`}
                                data-testid={'questionFeedback'}
                                inputProps={{ 'data-testid': 'questionFeedbackInput' }}
                                fullWidth
                                value={freeTextFeedbackInput?.questionFeedback}
                                onChange={(e) => handleQuestionInputChange(e.target.value)}
                                placeholder={`Enter text for ${freeTextFeedbackInput?.questionLabel}`}
                                margin="normal"
                                multiline
                                rows={4} // Defines the default visible number of rows
                                maxRows={10} // Limits the maximum number of rows before scrolling
                                variant="outlined" // Optionally use "outlined" to differentiate the field style

                            />
                        </div>}
                        {freeTextFeedbackInput?.answerLabel && <div >
                            <Typography component="Answerlabel" data-testid={'answerlabel'}>
                                {freeTextFeedbackInput?.answerLabel}
                            </Typography>
                            <TextField
                                id={`answerFeedback`}
                                data-testid={'answerFeedback'}
                                inputProps={{ 'data-testid': 'answerFeedbackInput' }}
                                fullWidth
                                value={freeTextFeedbackInput?.answerFeedback}
                                onChange={(e) => handleAnswerInputChange(e.target.value)}
                                placeholder={`Enter text for ${freeTextFeedbackInput?.answerLabel}`}
                                margin="normal"
                                multiline
                                rows={4} // Defines the default visible number of rows
                                maxRows={10} // Limits the maximum number of rows before scrolling
                                variant="outlined" // Optionally use "outlined" to differentiate the field style
                            />
                        </div>}
                        {/* Error message */}
                        {error && (
                            <Typography color="rgb(236 141 94)" variant="body2">
                                {error}
                            </Typography>
                        )}

                        <Button
                            variant={customStyle}
                            onClick={handleSubmit}
                            data-testid={'saveBtn'}
                            sx={{ marginTop: 2, width: "50%", textTransform: "none" }}
                        >
                            {t("Save").toLowerCase()}
                        </Button>

                    </Box>)}
        </Modal>
    )
};

const inputBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    boxShadow: 0,
    color: "black",
};
export const ntloadingBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    boxShadow: 0,
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    background: variables.white,
    borderRadius: "15px",
    marginBottom: "2px",
    width: "25%",
    height: "10%",
    padding: "20px",
    display: "flexDirection",
    justifyContent: "center",
    alignItems: "center",
};
export default FreeTextInputDialog;
