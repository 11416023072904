import React from "react";
import './ImaColorInfo.css';

const BlueCard = () => {
    return (
        <div className="top-card">
            <section className="congrats-section">
                <h1>Congratulations!</h1>
                <div className="intro-container">
                    <div className="top-right-image">
                        <img src="/blue.svg" alt="iMA High Blue" style={{
                            transform: "rotate(30deg)"
                        }}/>
                    </div>
                    <p className="intro-text">
                        When someone asks, "What Colour are You?" tell them "<strong>iMA High Blue</strong>"!
                    </p>
                    <p className="intro-text">
                        You are a logical well-organised person who works slowly and methodically to produce accurate systems. You need guarantees that you are in the right before you act and prefer to work at a slow pace to allow you time to "process" all the information provided.
                    </p>
                </div>
            </section>

            <section className="answer-yes-section">
                <h2>iMA High Blues answer Yes!</h2>
                <p>High Blues tend to answer "Yes!" to many of the following questions:</p>
                <div className="questions-grid">
                    <div>
                        <ul>
                            <li>Do you prefer to work alone and focus on facts, data, and processes?</li>
                            <li>Do you rarely (if ever) make impulsive decisions?</li>
                            <li>Do you make rational decisions based upon evidence?</li>
                            <li>Are you an inventive, creative intellectual?</li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>Are you good at perfecting processes and systems?</li>
                            <li>Do you prefer a work environment that is neat and tidy?</li>
                            <li>Is your motto "A place for everything and everything in its place"?</li>
                            <li>Do your rational thoughts and logic run the show and sometimes run your dreams into the ground?</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="needs-section">
                <h3>High Blues Need: Friendship</h3>
                <div className="needs-grid">
                    <ul>
                        <li><strong>You Want:</strong> To be correct</li>
                        <li><strong>For Others:</strong> To be precise</li>
                    </ul>
                    <ul>
                        <li><strong>To Maintain:</strong> Credibility</li>
                        <li><strong>Excited by:</strong> Reason</li>
                    </ul>
                </div>
            </section>

            <footer>
                <img src="/edf_logo.svg" width="200px" alt=""></img>
            </footer>
        </div>
    );
};

export default BlueCard;