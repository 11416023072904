import React from 'react';
import './ImaColorInfo.css';

const GreenCard = () => {
    return (
        <div className="top-card">
            <section className="congrats-section">
                <h1>Congratulations!</h1>
                <div className="intro-container">
                    <div className="top-right-image">
                        <img src="/green.svg" alt="iMA High Green" style={{
                            transform: "rotate(30deg)"
                        }}/>
                    </div>
                    <p className="intro-text">
                        When someone asks, "What Colour are You?" tell them "<strong>iMA High Green</strong>"!
                    </p>
                    <p className="intro-text">
                        You are a pleasant co-operative warm supportive team player. You need reassurance that you are
                        liked and personal assurances that you are doing a good job help you to feel comfortable within
                        your job.
                    </p>
                </div>
            </section>

            <section className="answer-yes-section">
                <h2>iMA High Greens answer Yes!</h2>
                <p>High Greens tend to answer "Yes!" to many of the following questions:</p>

                <div className="questions-grid">
                    <div>
                        <ul>
                            <li>Do you prefer stability at work and at home? Do you tend to avoid risks and change, and
                                only accept change a little at a time?
                            </li>
                            <li>Do you take your time to make the "right" decisions and you often seek advice from
                                others?
                            </li>
                            <li>Do you dislike pushy, aggressive behaviour?</li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>Are you a natural team player who likes to be involved in groups as opposed to
                                independent activities?
                            </li>
                            <li>At work, do you prefer to know the step-by-step sequence of projects and tasks?</li>
                            <li>Do you tend to procrastinate getting started until everything is in place?</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="needs-section">
                <h3>High Greens Need: Thoroughness</h3>
                <div className="needs-grid">
                    <ul>
                        <li><strong>You Want:</strong> To be Liked</li>
                        <li><strong>For Others:</strong> To be Pleasant</li>
                    </ul>
                    <ul>
                        <li><strong>To Maintain:</strong> Relationships</li>
                        <li><strong>Excited by:</strong> Productive routine</li>
                    </ul>
                </div>

            </section>

            <footer>
                <img src="/edf_logo.svg" width="200px" alt=""></img>
            </footer>
        </div>
    );
};

export default GreenCard;
