import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { MenuOpenRounded, NotificationsOutlined } from "@mui/icons-material";
import variables from "../../variables.sass";
import MenuOpenIcon from "../icons/MenuOpenIcon";
import MessageIcon from "../icons/MessageIcon";
import "./Navbar.sass";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import NavLinkTxt from "../common/navLink/NavLinkTxt";
import NotificationView from "../common/notification/NotificationView";
import { useState } from "react";
import LauralBadge from "../navbar/LauralBadge";
import LauralBadgeModal from "../navbar/LauralBadgeModal";
import ImaColorInfo from "../imacolors/ImaColorInfo";

const NavbarQA = ({ t, open, handleOpen }) => {
    const pendingQuestions = useSelector((state) => state.pendingQuestions.pendingQuestions);
    const pluralPending = pendingQuestions?.count > 1 ? "s" : "";
    const dashboardInfo = useSelector((state) => state.dashboardInfoQA);
    const notifications = useSelector((state) => state.notifications);
    const [lauralBadgeModalOpen, setLauralBadgeModalOpen] = useState(false);
    const [isImaModalOpen, setIsImaModalOpen] = useState(false);
    const [imaModalImageUrl, setImaModalImageUrl] = useState('');


    const openImaModal = (imageUrl) => {
        setImaModalImageUrl(imageUrl);
        setIsImaModalOpen(true);
    };


    const closeImaModal = () => {
        setIsImaModalOpen(false);
    };

    const isLastQuestionInProgress = useSelector((state) => state.questionAnswer.isLastQuestionInProgress);
    let isInfoMode = useSelector((state) => state.dashboardInfoQA?.isInfoMode)
    const errorInfo = useSelector((state) => state.questionAnswer.errorInfo);
    let customLinkText = dashboardInfo?.customLinkText;

    const location = useLocation();

    const isErrorStatus = !!errorInfo || location.pathname.includes("error") || location.pathname.includes("performance") //todo should not check location find another way
    const isNavActionAllowed =
        isErrorStatus || (isLastQuestionInProgress != undefined && !isLastQuestionInProgress);

    const isNotificationEnabled = isErrorStatus || (notifications?.notificationCount > 0 && (isInfoMode || (isLastQuestionInProgress != undefined && !isLastQuestionInProgress)));

    const [displayNotifications, setDisplayNotifications] = useState(false);

    const PendingBar = () => <>
        <NavLinkTxt
            dataTestId="pending-questions"
            reloadDocument
            tabIndex={0}
            toLink={pendingQuestions?.path + pendingQuestions?.nextTrackingCode}
            className="pending-questions"
            innerHtml={t(`You have x pending question${pluralPending}`, {
                amount: pendingQuestions?.count,
            })}
            target={"_self"} />
        <MessageIcon className="pending-questions-icon" />
    </>;

    const NotificationBadge = () => {
        return (<div
            role="button"
            tabIndex={0}
            onClick={isNotificationEnabled ? () => {
                setDisplayNotifications(true);
            } : null}
            style={{
                paddingLeft: "0.5em",
                position: 'relative',
                cursor: isNavActionAllowed ? 'pointer' : 'default',
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            onKeyDown={(e) => {
                if (e.key === " " || e.key === "Enter") {
                    e.currentTarget.click();
                }
            }}
            title={isNotificationEnabled ? t("notification.openNotifications") : t("notification.noData")}
            data-testid="notifications"
        >
            <NotificationsOutlined
                style={isNotificationEnabled ? { color: variables.nelly } : { color: variables.grayAccessible }}
                sx={(theme) => {
                    return {
                        [theme.breakpoints.down("sm")]: {
                            width: "16px",
                            height: "16px"
                        }
                    }
                }}
            />
            {
                notifications?.unreadNotificationQty > 0 && isNotificationEnabled && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-8px',
                            right: '-8px',
                            backgroundColor: '#e60000',
                            borderRadius: '50%',
                            padding: '1.5px',
                            color: '#ffffff',
                            fontSize: '0.75rem',
                            minWidth: '16px',
                            textAlign: 'center',
                        }}
                    >
                        <span data-testid="notificationQty" style={{ fontWeight: 'bold' }}>{notifications.unreadNotificationQty}</span>
                    </div>
                )}
        </div>)
    };
    return (
        <Box
            sx={(theme) => {
                return {
                    flex: "1 1 64px",
                    maxHeight: "9vh",
                    minHeight: "9vh",
                    height: "9vh",
                    position: "sticky",
                    top: 0,
                    right: 0,
                    zIndex: 1000,
                    boxShadow: `-10px -10px -10px -10px ${variables.shadow}`,
                    [theme.breakpoints.down("md")]: {
                        flex: "1 1 56px",
                        maxHeight: "56px",
                        minHeight: "56px",
                        height: "56px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "8px",
                    },
                };
            }}
        >
            <AppBar
                position="absolute"
                sx={{
                    backgroundColor: "rgb(253, 253, 253)",
                }}
            >
                <Toolbar data-testid="navbar">
                    {open && (
                        <IconButton
                            sx={{
                                color: "#5D5D5D",
                                ":focus-visible": {
                                    border: "2px black solid",
                                },
                            }}
                            data-testid="close-sidebar-btn"
                            aria-label="close sidebar"
                            type="button"
                            edge="start"
                            tabIndex={60}
                            onClick={() => handleOpen(false)}
                        >
                            <MenuOpenRounded />
                        </IconButton>
                    )}
                    {!open && (
                        <IconButton
                            sx={{
                                color: "#5D5D5D",
                                ":focus-visible": {
                                    border: "2px black solid",
                                },
                            }}
                            aria-label="open sidebar"
                            type="button"
                            onClick={() => handleOpen(true)}
                            edge="start"
                            tabIndex={60}
                            data-testid="open-sidebar-btn"
                        >
                            <MenuOpenIcon />
                        </IconButton>
                    )}
                    <LauralBadge setLauralBadgeModalOpen={setLauralBadgeModalOpen} />
                    <LauralBadgeModal lauralBadgeModalOpen={lauralBadgeModalOpen} setLauralBadgeModalOpen={setLauralBadgeModalOpen} />
                    <ImaColorInfo isOpen={isImaModalOpen} imageUrl={imaModalImageUrl} onClose={closeImaModal} customStyle={dashboardInfo?.customStyle} />
                    <div className="navbar-info">
                        {pendingQuestions?.count > 0 && pendingQuestions?.nextTrackingCode !== "" && <>{isNavActionAllowed && <PendingBar />}</>}
                        {customLinkText && (
                            <NavLinkTxt
                                dataTestId={"customLinkText"}
                                reloadDocument
                                toLink={customLinkText?.link}
                                className="customLinkText menu"
                                innerHtml={customLinkText?.label}
                                target={"_blank"}
                            />
                        )}
                        <div id="menu-user-btn" data-testid="menu-user-btn" color="primary" style={{ display: "flex", marginRight: ".5rem" }}>
                            <p className={`user-name  ${dashboardInfo?.customStyle}`}>{dashboardInfo?.fullName}</p>

                            <div onClick={() => openImaModal(dashboardInfo?.imaColorData?.imaColor)} id="myAvatarRoot" style={{
                                color: dashboardInfo?.imaColorData?.imaColor?(dashboardInfo?.imaColorData?.imaColor==="BLUE"? "white":"black"):"undefined",
                                backgroundImage:  dashboardInfo?.imaColorData?.imaColor? `url(/${dashboardInfo?.imaColorData?.imaColor}.svg)`:'none',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }} className={`avatar  ${dashboardInfo?.customStyle}`} >
                                <p>{dashboardInfo?.fullNameInitials}</p>
                            </div>
                            <NotificationBadge />
                            {displayNotifications && <NotificationView setDisplayNotifications={setDisplayNotifications} />}
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );



};

export default withTranslation()(NavbarQA);
