import { Checkbox, useTheme, Button } from "@mui/material";
import ThumbsDownIcon from "../../icons/ThumbsDownIcon";
import ThumbsUpIcon from "../../icons/ThumbsUpIcon";
import LightbulbIcon from "../../icons/LightbulbIcon";
import "./Answer.sass";
import { useState, useEffect, useRef } from "react";

import { withTranslation } from "react-i18next";
import SurveyIcon from "../../icons/SurveyIcon";

const AnswerOptions = ({
    t,
    onClickAction,
    optionList,
    isQInProgress,
    isQuestionAnsweredCorrectly,
    isCheckboxEnabled,
    displayOptionLetter,
    submitAction,
    customStyle,
    isSurveyQuestion,
}) => {
    const theme = useTheme();
    const [selectedOptionIdList, setSelectedOptionIdList] = useState([]);

    const updateSelectedOptionsState = (optionId) => {
        let selectedOptionId = selectedOptionIdList.find((selectedId) => selectedId == optionId);
        selectedOptionId ? setSelectedOptionIdList(selectedOptionIdList.filter((el) => el !== optionId)) : setSelectedOptionIdList([...selectedOptionIdList, optionId]);
    };

    const handleClick = (option) => {
        onClickAction ? onClickAction(option.answerKey) : updateSelectedOptionsState(option.answerKey);
    };

    let styleName = "answer";
    const AnswerImage = ({ option }) => {
        const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

        useEffect(() => {
            const img = new Image();

            img.onload = () => {
                setImageSize({ width: img.width, height: img.height });
            };
            img.src = option?.answerImage;
        }, [option?.answerImage]);

        return (
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                }}
            >
                <img
                    data-testid={`AnswerImage${option?.answerKey}`}
                    src={option.answerImage}
                    className={`answer-image ${customStyle}`}
                    width={(imageSize.width * option.answerImageScale) / 100}
                    height={(imageSize.height * option.answerImageScale) / 100}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                />
            </div>
        );
    };

    const OptionListButtons = () => {
        return optionList.map((option, idx) => {
            let optionDisplayStyle;
            if (!isSurveyQuestion)
                //todo can handle from Css or be send answer icons  ?
                optionDisplayStyle = findAnswerFeedBackIconAndStyle(option);
            else optionDisplayStyle = findSurveyFeedBackIconAndStyle(option);
            let keyDownTimeout;
            return (
                <div
                    id={"answerOption-" + option?.answerKey}
                    tabIndex={30 + idx}
                    role={isQInProgress ? "button" : "none"}
                    aria-label={`${option?.text} ${optionDisplayStyle.ariaLabel}`}
                    data-testid={option?.answerKey}
                    key={option?.answerKey}
                    onKeyDown={(e) => {
                        keyDownTimeout = handleAnswerViaKeyboard(e, optionList, idx, true);
                    }}
                    onKeyUp={() => {
                        if (keyDownTimeout) {
                            clearTimeout(keyDownTimeout);
                        }
                    }}
                    onClick={() => handleClick(option)}
                    className={`
                ${styleName} ${theme.mode}  ${customStyle}
                ${optionDisplayStyle.style}
                ${!isQInProgress ? "finished" : ""}
                ${option.answerImage ? "answerImage" : ""}
            `}
                >
                    {!!isCheckboxEnabled && (
                        <div id={"answer-CheckBox" + option.answerKey} data-testid={`Checkbox${option?.answerKey}`} className={`action  ${customStyle}`}>
                            <p className={`check  ${customStyle}`}>
                                <Checkbox checked={!!selectedOptionIdList.find((optionId) => option.answerKey == optionId)} size="small" tabIndex={-1} />
                            </p>
                        </div>
                    )}
                    {displayOptionLetter && (
                        <p data-testid={`ShuffleLetter${option?.answerKey}`} id={option.answerKey} className="letter">
                            {String.fromCharCode("A".charCodeAt(0) + idx)})
                        </p>
                    )}
                    {option.answerImage && <AnswerImage option={option} />}
                    <p id={"answerText-" + option?.answerKey} className={`text  ${customStyle}`} dangerouslySetInnerHTML={{ __html: option?.text || "" }} />
                    <div id={"answerIcon" + option.answerKey} className={`action  ${customStyle}`}>
                        {optionDisplayStyle?.icon}
                    </div>
                </div>
            );
        });
    };
    const findSurveyFeedBackIconAndStyle = (option) => {
        return {
            icon: option.isSelected ? <SurveyIcon data-testid={`SurveyIcon${option?.answerKey}`} /> : undefined,
            style: option.isSelected ? "survey" : "",
        };
    };

    const calculateDisplayProps = (isError, isSuccess, isPartiallySuccess, isReveal, option, t) => {
        let result = {
            alabel: "",
            style: "",
            icon: <LightbulbIcon data-testid={`hiddenLightbulbIcon${option?.answerKey}`} visibility="hidden" />,
        };
        if (isSuccess) {
            result.alabel = t("CorrectAndSelected");
            result.style = "success";
            result.icon = <ThumbsUpIcon data-testid={`ThumbsUpIcon${option?.answerKey}`} />;
        } else if (isPartiallySuccess) {
            result.alabel = t("CorrectAndSelected");
            result.style = "success";
            result.icon = <LightbulbIcon data-testid={`LightbulbIcon${option?.answerKey}`} />;
        } else if (isError) {
            result.alabel = t("Incorrect");
            result.style = "error";
            result.icon = <ThumbsDownIcon data-testid={`ThumbsDownIcon${option?.answerKey}`} />;
        } else if (isReveal) {
            result.alabel = t("Correct");
            result.style = "reveal";
            result.icon = <LightbulbIcon data-testid={`revealLightbulbIcon${option?.answerKey}`} />;
        }

        return result;
    };

    const findAnswerFeedBackIconAndStyle = (option) => {
        let isAnswered = option?.isCorrect !== undefined && option?.isCorrect !== null;
        let isError = isAnswered && !option?.isCorrect && !!option?.isSelected;
        let isSuccess = isAnswered && !!option?.isCorrect && !!option?.isSelected && !!isQuestionAnsweredCorrectly;
        let isPartiallySuccess = isAnswered && !!option?.isCorrect && !!option?.isSelected;
        let isReveal = isAnswered && !!option?.isCorrect && !option?.isSelected;

        return calculateDisplayProps(isError, isSuccess, isPartiallySuccess, isReveal, option, t);
    };

    return (
        <>
            <OptionListButtons />
            <SubmitButton
                submitAction={submitAction}
                customStyle={customStyle}
                selectedOptionIdList={selectedOptionIdList}
                setSelectedOptionIdList={setSelectedOptionIdList}
                t={t}
            />
        </>
    );
};
const handleAnswerViaKeyboard = (e, optionList, idx, focusNext = false) => {
    if (e.key === " " || e.key === "Enter") {
        e.currentTarget.click();
        if (focusNext && optionList && optionList.length > 0 && typeof idx === "number") {
            return setTimeout(() => {
                if (optionList.length - 1 !== idx) {
                    document.getElementById("answerOption-" + optionList[idx + 1].answerKey).focus();
                } else if (document.getElementById("SubmitButton") && !document.getElementById("SubmitButton").disabled) {
                    document.getElementById("SubmitButton").focus();
                } else {
                    document.getElementById("answerOption-" + optionList[0].answerKey).focus();
                }
            }, 20);
        }
    }
};
const SubmitButton = ({ submitAction, customStyle, selectedOptionIdList, setSelectedOptionIdList, t }) => {
    return (
        submitAction && (
            <Button
                variant={customStyle}
                className={`submit-button ${customStyle}`}
                disabled={selectedOptionIdList?.length === 0}
                id="SubmitButton"
                data-testid="SubmitButton"
                tabIndex={39}
                onKeyDown={(e) => {
                    handleAnswerViaKeyboard(e);
                }}
                onClick={() => {
                    submitAction(selectedOptionIdList);
                    setSelectedOptionIdList([]);
                }}
            >
                {t("Submit")}
            </Button>
        )
    );
};
export default withTranslation()(AnswerOptions);
