import React from "react";
import {Button, Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import './ImaCard.css';

const ImaCardsAll = ({open, handleClose}) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth PaperProps={{
            style: { width:'90%',maxHeight:'100%' }
        }}>
            <DialogContent style={{backgroundColor: '#ffffff', borderRadius: '50px'}}>
                <div className="allCards">

                    <div className="ima-container">
                        {/* Quadrant Sections */}
                        <div className="quadrant blue">
                            <div className="quadrant-header">
                                <h2>iMA High Blue</h2>
                            </div>
                            <div className="quadrant-content">
                                <p className="centerText">...are serious, analytical, persistent, systematic and task-oriented people who enjoy problem solving, perfecting processes and working towards tangible results.</p>
                                <p className="centerText"> They do resarch, make comparisons, determine risk, calculate margins of error and take action.</p>
                                <ul >
                                    <li>Be time disciplined</li>
                                    <li>Be logical and prepared</li>
                                    <li>Be precise</li>
                                    <li>Be respectful of rules</li>
                                    <li>Be structured and well organized</li>
                                </ul>
                            </div>
                        </div>
                        <div className="quadrant green">
                            <div className="quadrant-header">
                                <h2>iMA High Green</h2>
                            </div>

                            <div className="quadrant-content">
                                <p className="centerText">...are warm, supportive and nurturing individuals who develop strong networks of people who are willing to be mutual supportive and reliable</p>
                                <p className="centerText">.They're excellent team players, courteous, friendly, good planners; persistent workers and good with follow through.</p>
                                <ul>
                                    <li>Be pleasant</li>
                                    <li>Be non-assertive</li>
                                    <li>Be selfless</li>
                                    <li>Be sincere</li>
                                    <li>Be supportive of my feelings</li>
                                </ul>
                            </div>
                        </div>
                        <div className="quadrant red">
                            <div className="quadrant-header">
                                <h2>iMA High Red</h2>
                            </div>
                            <div className="quadrant-content">
                                <p className="centerText">...are goal-oriented go-getters who are most comfortable when in charge of people and situations.</p>
                                <p className="centerText">They focus on a no-nonsense approach to bottom-line results. They are fast paced; task oriented and work quickly by themselves.</p>
                                <ul >
                                    <li>Be practical</li>
                                    <li>Be brief</li>
                                    <li>Be assertive</li>
                                    <li>Be to the point</li>
                                    <li>Be supportive of my goals</li>
                                </ul>
                            </div>
                        </div>
                        <div className="quadrant yellow">
                            <div className="quadrant-header">
                                <h2>iMA High Yellow</h2>
                            </div>
                            <div className="quadrant-content">
                                <p className="centerText">...are outgoing, friendly, and enthusiastic idea people who excel in getting others
                                    excited about their vision</p>
                                <p className="centerText">They are fast paced, high energy and deal with people in a positive upbeat way; eternal optimists that can influence people and build alliances to accomplish their goals</p>
                                <ul >
                                    <li>Be flexible</li>
                                    <li>Be quick-paced</li>
                                    <li>Be positive</li>
                                    <li>Be supportive of my idea</li>
                                    <li>Be generous with your praise</li>
                                </ul>
                            </div>
                        </div>

                        {/* Center section with arrows */}
                        <div className="center">
                            <img src="/blue.svg" alt="Image Top Left" className="image-top-left"/>
                            <img src="/green.svg" alt="Image Top Right" className="image-top-right"/>
                            <img src="/red.svg" alt="Image Bottom Left" className="image-bottom-left"/>
                            <img src="/yellow.svg" alt="Image Bottom Right" className="image-bottom-right"/>
                            {/* Arrows */}
                            <div className="arrow arrow-left">
                                <span className="arrow-text">Task Orientation / Self Contained </span>
                            </div>
                            <div className="arrow arrow-right">
                                <span className="arrow-text">Relationship Orientation / Open</span>
                            </div>
                            <div className="arrow arrow-top">
                                <span className="arrow-text">Non-Assertive</span>
                            </div>
                            <div className="arrow arrow-bottom">
                                <span className="arrow-text">Assertive</span>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ImaCardsAll;