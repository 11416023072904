import "./Note.sass";
import { InfoIcon } from "../../icons/InfoIcon";
import { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "react-oauth2-code-pkce";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit'; // You can use Material-UI or any icon library
import { Grid } from "@mui/material";

const Note = ({ note, noteDetail, uniqueId, customStyle, disableInfoIcon, notelinkClicked, isEditable = false, editClicked }) => {

    const { token } = useContext(AuthContext);
    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);
    const location = useLocation();
    const isSso = location.pathname.startsWith("/sso");

    useEffect(() => {
        if (document.getElementById(`current_note_element-${uniqueId}`)?.getElementsByTagName("a")) {
            let links = document.getElementById(`current_note_element-${uniqueId}`)?.getElementsByTagName("a");
            Object.entries(links).forEach((el) => {
                let link = el[1];
                let url = link?.getAttribute("href");
                if (url) {
                    if (!url?.startsWith("http")) {
                        url = "https://" + url; // TODO link validation should be handled backend-side or plato
                    }
                    link.addEventListener("click", (event) => {
                        event.preventDefault();
                        window.open(url, '_blank');
                        notelinkClicked(url, isSso, token, trackingCode);
                    });
                }
            });
        }

    }, [note]);


    return (
        note?.length > 0 && (
            <div className={`note  ${customStyle}`} id={`current_note_element-${uniqueId}`} data-testid={`note${uniqueId}`}>
                <Grid>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'left' }}>
                            {!disableInfoIcon && <InfoIcon uniqueId={uniqueId} style={{ marginRight: '10px' }} />}
                            <p tabIndex={39} dangerouslySetInnerHTML={{ __html: note }} style={{ margin: 0 }}></p>

                        </div>
                    </div>
                    {noteDetail && (
                        <div className={`note ${customStyle} noteDetail`} style={{ textAlign: 'left', marginTop: '10px' }}>
                            <p tabIndex={40} dangerouslySetInnerHTML={{ __html: noteDetail }}></p>
                        </div>
                    )}
                </Grid>
                {isEditable && (
                    <EditIcon
                        data-testid={`edit${uniqueId}`}
                        tabIndex={41}
                        onClick={() => editClicked && editClicked()} // Define the action on click
                        style={{ cursor: 'pointer', marginLeft: '10px' }} // Add some styling
                        aria-label="Edit Note"
                    />
                )}
            </div>)
    );
};

export default Note;
