import React from "react";
import './ImaColorInfo.css';

const YellowCard = () => {
    return (
        <div className="top-card">
            <section className="congrats-section">
                <h1>Congratulations!</h1>
                <div className="intro-container">
                    <div className="top-right-image">
                        <img src="/yellow.svg" alt="iMA High Yellow" style={{
                            transform: "rotate(30deg)"
                        }}/>
                    </div>
                    <p className="intro-text">
                        When someone asks, "What Colour are You?" tell them "<strong>iMA High Yellow</strong>"!
                    </p>
                    <p className="intro-text">
                        You and all other High Yellows want to be admired. You need to feel that you are getting the credit you deserve and are quick to draw attention to your achievements.
                    </p>
                </div>
            </section>

            <section className="answer-yes-section">
                <h2>iMA High Yellows answer Yes!</h2>
                <p>High Yellows tend to answer "Yes!" to many of the following questions:</p>
                <div className="questions-grid">
                    <div>
                        <ul>
                            <li>Do you prefer to interact often with others at work and at home?</li>
                            <li>Is this important to you because you dislike tedium and routine?</li>
                            <li>Do you enjoy running new ideas past other people and gauging their reactions?</li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>Do you tend to make spontaneous decisions?</li>
                            <li>Do others see you as a motivational team player?</li>
                            <li>Are you so busy thinking about the future that you forget the here and now?</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="needs-section">
                <h3>High Yellows Need: Approval</h3>
                <div className="needs-grid">
                    <ul>
                        <li><strong>You Want:</strong> To be Admired</li>
                        <li><strong>For Others:</strong> To be Stimulating</li>
                    </ul>
                    <ul>
                        <li><strong>To Maintain:</strong> Status</li>
                        <li><strong>Excited by:</strong> Exchanging ideas</li>
                    </ul>
                </div>
            </section>

            <footer>
                <img src="/edf_logo.svg" width="200px" alt=""></img>
            </footer>
        </div>
    );
};

export default YellowCard;