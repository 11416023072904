import { Box, CircularProgress, Grid, Modal } from "@mui/material";

export const LoadingGrid = ({ t }) => {
    return (
        <Modal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={loadingBoxStyle} gap={2}>
                <CircularProgress color="primary" />
                {t("Please wait...")}
            </Box>
            {/*
            previously version for loading, on the top of question, in feedback area
           <Grid container className="loading">
                <Grid item className="loading-box">
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>
            */}
        </Modal>
    );
};
export const loadingBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "transparent",
    border: "none",
    boxShadow: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
};